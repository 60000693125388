


































































































import camelcaseKeys from 'camelcase-keys';
import Component, { mixins } from 'vue-class-component';
import { getAvailableAccounts } from '../api/inventory-asset-requisition-notes-api';
import InventoryAssetRequisitionNoteMixin from './inventory-asset-requisition-notes-mixin.vue';

@Component
export default class extends mixins(InventoryAssetRequisitionNoteMixin) {
  syncAccountingFields: Array<any> = [
    {
      key: 'idx',
      label: 'Nr. crt.',
      thClass: 'text-center tbl-col-idx',
      tdClass: 'text-center py-2 tbl-col-idx',
    },
    {
      key: 'product',
      label: 'Produs',
      thClass: 'tbl-col-product-published',
      tdClass: 'py-2 tbl-col-product-published',
    },
    {
      key: 'requisitionedQuantity',
      label: 'Cant. consumată',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'inventoryUnitPrice',
      label: 'Pret unitar',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'inventoryRequisitionValue',
      label: 'Valoare',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'inventoryAccount',
      label: 'Cont gest.',
      thClass: 'tbl-col-inventory-account',
      tdClass: 'tbl-col-inventory-account',
    },
    {
      key: 'expenseAccount',
      label: 'Cont chelt.',
      thClass: 'tbl-col-expense-account',
      tdClass: 'tbl-col-expense-account',
    },
    {
      key: 'inventoryAssetAccount',
      label: 'Cont ob. inv.',
      thClass: 'tbl-col-inventory-account',
      tdClass: 'tbl-col-inventory-account',
    },
  ];

  syncAccountingItems: Array<any> = [];

  syncAccountingExpenseAccounts: Array<any> = [];
  syncAccountingExpenseAccountsFiltered: Array<any> = [];

  syncAccountingExpenseAccountsSearch(query: string) {
    this.syncAccountingExpenseAccountsFiltered = this.syncAccountingExpenseAccounts.filter((x) => x.label.toUpperCase().indexOf(query.toUpperCase()) !== -1);
    return Promise.resolve();
  }

  async onSyncAccountingModalCancel(evt) {
    evt.preventDefault();

    if (await this.$bvModal.msgBoxConfirm('Confirmi că vrei să renunți la sinconizarea documentului?')) {
      (<any>(this.$refs.accountingSyncModal)).hide();
    }
  }

  async onSyncAccountingModalOk(evt) {
    const hasEmptyExpenseAccount = !!this.syncAccountingItems.find((item) => !item.expenseAccount);

    if (hasEmptyExpenseAccount) {
      evt.preventDefault();
      await this.$bvModal.msgBoxOk('Nu au fost completate toate conturile de cheltuială!');
      return;
    }

    const postData = {
      lines: this.syncAccountingItems.map((item) => ({
        inventoryAssetRequisitionNoteLineId: item.inventoryAssetRequisitionNoteLineId,
        inventoryAssetRequisitionNoteLineInventoryAllocationId: item.inventoryAssetRequisitionNoteLineInventoryAllocationId,
        expenseAccountId: item.expenseAccount.internalId,
      })),
    };

    this.$emit('onSyncAccountingModalOk', postData);
  }

  async initialize() {
    const allAccounts = camelcaseKeys(await getAvailableAccounts()).map((e) => ({ ...e, label: `${e.numar} - ${e.denumire}` }));

    this.syncAccountingExpenseAccounts = allAccounts.filter((e) => (e.numar.startsWith(6) || e.numar.startsWith(711) || e.numar.startsWith(4734)));
    this.syncAccountingExpenseAccountsFiltered = this.syncAccountingExpenseAccounts;
  }

  hideModal() {
    (<any>(this.$refs.accountingSyncModal)).hide();
  }

  async showModal(items) {
    this.syncAccountingItems = items || [];

    await this.initialize();

    (<any>(this.$refs.accountingSyncModal)).show();
  }
}
