import httpClient from '@/services/http-client';

export async function lookupInventory(lookupTerm) {
  const response = await httpClient.get('/api/lookup/inventory', {
    params: {
      lookupTerm,
    },
  });

  return response.data;
}

export async function lookupProduct(lookupTerm) {
  const response = await httpClient.get('/api/lookup/product', {
    params: {
      lookupTerm,
    },
  });

  return response.data;
}

export async function getOnlyProductWithAvailableStock(payload) {
  const response = await httpClient.get('/api/adm/product-catalog/products-with-available-stocks', {
    params: {
      ...payload,
    },
  });

  return response.data;
}

export async function lookupEmployee(lookupTerm) {
  const response = await httpClient.get('/api/lookup/employee', {
    params: {
      lookupTerm,
    },
  });

  return response.data;
}

export async function getDocumentLogs(documentId) {
  const response = await httpClient.get(`/api/document-log-entry/iarn/${documentId}`);
  return response.data;
}

export async function getAvailableStock({
  lookupDate, productId, inventoryId, unitPrice = null,
}) {
  const response = await httpClient.get('/api/lookup/stock', {
    params: {
      lookupDate,
      productId,
      inventoryId,
      unitPrice,
    },
  });

  return response.data;
}

export async function getAvailableInventories() {
  const response = await httpClient.get('/api/bco/inventories');

  return response.data;
}

export async function getAvailableSeries(seriesYear) {
  const response = await httpClient.get('/api/bco/series', {
    params: {
      seriesYear,
    },
  });

  return response.data;
}

export async function getInventoryAssetRequisitionNotesList(pageNumber, perPage, filters) {
  const response = await httpClient.get('/api/bco/list', {
    params: {
      pageNumber: pageNumber || 1,
      perPage: perPage || 25,
      filters: JSON.stringify(filters),
    },
  });

  return response.data;
}

export async function saveDraft(draft) {
  const response = await httpClient.post('/api/bco/draft', draft);

  return response.data;
}

export async function deleteDraft(id) {
  const response = await httpClient.delete(`/api/bco/draft/${id}`);

  return response.data;
}

export async function deleteCanceled(id) {
  const response = await httpClient.delete(`/api/bco/canceled/${id}`);

  return response.data;
}

export async function cancelDocument(id) {
  const response = await httpClient.post(`/api/bco/cancel/${id}`);

  return response.data;
}

export async function restoreDocument(id) {
  const response = await httpClient.post(`/api/bco/restore/${id}`);

  return response.data;
}

export async function postDraft(id) {
  const response = await httpClient.post(`/api/bco/post/${id}`);

  return response.data;
}

export async function getDetails(id) {
  const response = await httpClient.get(`/api/bco/details/${id}`);

  return response.data;
}

export async function compareLatestSeries(id) {
  const response = await httpClient.get(`/api/bco/compare-latest-series/${id}`);

  return response.data;
}

export async function getPdf(id, numCopies, responseType) {
  const response = await httpClient.get(`/api/bco/pdf/${id}`, {
    params: {
      copies: numCopies || 1,
      cacheBustTimestamp: Date.now(), // prevents IE cache problems on re-download
    },
    // PrintJS knows of arraybuffers only
    responseType: responseType || 'arraybuffer',
    // This is in milliseconds, so wait up to 15 seconds
    timeout: 15 * 1000,
    headers: {
      Accept: 'application/octet-stream',
    },
  });

  return response.data;
}

export async function getInventory(inventoryId) {
  const response = await httpClient.get('/api/bco/inventory/', {
    params: {
      inventoryId,
    },
  });

  return response.data;
}

export async function getProduct(productId) {
  const response = await httpClient.get('/api/bco/product', {
    params: {
      productId,
    },
  });

  return response.data;
}

export async function getEmployee(employeeId) {
  const response = await httpClient.get('/api/bco/employee', {
    params: {
      employeeId,
    },
  });

  return response.data;
}

export async function updateDocumentFooter(id, data) {
  const response = await httpClient.patch(`/api/bco/footer/${id}`, data);

  return response.data;
}

export async function getAvailableAccounts() {
  const response = await httpClient.get('/api/adm/product-classification/accounts?includeClass8=true&includeClass4=true');
  return response.data;
}

export async function syncAccounting(id, data) {
  const response = await httpClient.post(`/api/bco/sync/${id}`, data);

  return response.data;
}

export async function unsyncAccounting(id) {
  const response = await httpClient.delete(`/api/bco/sync/${id}`);

  return response.data;
}

export async function getAccountingSyncData(id) {
  const response = await httpClient.get(`/api/bco/sync/${id}`);

  return response.data;
}

export async function addPublishedLine(id, line) {
  const response = await httpClient.post(`/api/bco/line/${id}`, line);

  return response.data;
}

export async function deletePublishedLine(id, lineId) {
  const response = await httpClient.delete(`/api/bco/line/${id}/${lineId}`);

  return response.data;
}
